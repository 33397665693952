/** @format */

import React, { useState, useEffect } from "react";
import Table from "../../../Components/Table/Index";
import { useGetAll } from "../../../Hooks/useGetAll";
import { red, green } from "@mui/material/colors";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";
import AddSponsors from "../../../Components/AdminPanelDialogs/AddSponsors/Index";
import { Link } from "react-router-dom";

// Import icons from Material-UI
import UrgentIcon from "@mui/icons-material/Warning"; // Icon for urgent
import NonUrgentIcon from "@mui/icons-material/CheckCircle"; // Icon for non-urgent

function Index() {
  const [limit, SetLimit] = useState(10);
  const [rows, setRows] = useState([]);

  const { data: sponsorslimit, refetch } = useGetAll({
    key: `/records/sponsors/nt/`,
    select: (data) => data,
    onSuccess: (data) => {
      // console.log(data, "<----------data");
      console.log(data, "========>limit");
      SetLimit(data?.data?.total_count);
    },
  });
  const { data: sponsers } = useGetAll({
    key: `/records/sponsors/nt/?limit=${limit}`,
    select: (data) => data?.data?.rows,
    onSuccess: (data) => {
      // console.log(data, "<----------data");
      setRows(mapApiDataToTableRows(data));
    },
  });
  const mapApiDataToTableRows = (apiData) => {
    return (
      apiData
        //   .filter((item) => item.exp_id) // Filter out rows without exp_id
        .map((item, index) => ({
          id: item.id, // Use exp_id or fallback to a unique index-based ID

          s_name: item.s_name,
          end_date: item?.end_date,
          since: item.since,
          donation_type: item.donation_type,
          address: item.address,
          no_of_students: item.no_of_students,
        }))
    );
  };

  const columns = [
    // { field: "exp_id", headerName: "ID", width: 70 },

    { field: "s_name", headerName: "Sponsor Name", width: 130 },
    {
      field: "since",
      headerName: "Start Date",
    },
    {
      field: "end_date",
      headerName: "End Date",
    },
    {
      field: "donation_type",
      headerName: "Donation Frequency",
    },
    {
      field: "address",
      headerName: "Date",
      type: "string",
      width: 90,
    },
    {
      field: "no_of_students",
      headerName: "Number on students",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Link to='Edit' state={{ id: params?.id }}>
          <PrimaryButton
            sx={{
              borderRadius: 1,
              height: "30px",
              background: "#8a8ada",
              "&:hover": {
                background: "#8a8adaba",
              },
            }}
          >
            Edit
          </PrimaryButton>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (sponsers) {
      setRows(mapApiDataToTableRows(sponsers));
    }
  }, [sponsers]);

  return (
    <div className=''>
      <Table
        columns={columns}
        title='sponsers'
        rows={rows}
        DeleteUrl={`/records/delete-multiple-sponsors/`}
        refetchUrl={`/records/sponsors/nt/`}
        addButton={<AddSponsors onSuccess={refetch} />}
      />
    </div>
  );
}

export default Index;
