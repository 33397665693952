/** @format */

import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
import { colors } from "../../../Constants/theme";

const columns = [
  {
    field: "s_name",
    headerName: "Name",
    flex: 2,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "no_of_students",
    headerName: "Number of Students",
    type: "number",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "donation_type",
    headerName: "Frequency",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "since",
    headerName: "Start Date",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "address",
    headerName: "Location",
    flex: 2,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
        {params.value}
      </div>
    ),
  },
];

export default function DataTable({ data, length }) {
  const isScreenSmall = useMediaQuery("(max-width:751px)");

  const columnVisibilityModel = {
    since: !isScreenSmall, // Hide 'Start Date' on small screens
    address: !isScreenSmall, // Hide 'Location' on small screens
  };

  const dataWithSrNo = data.map((row, index) => ({
    ...row,
    srNo: index + 1,
  }));

  return (
    <Paper
      sx={{
        width: "100%",
        bgcolor: colors.tertiary.gradient,
        boxShadow: 2,
        borderRadius: 2,
        padding: 2,
      }}
    >
      <DataGrid
        rows={dataWithSrNo}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: length } },
        }}
        pageSizeOptions={[length]}
        // getRowHeight={() => "auto"} // Adjust row height dynamically
        sx={{
          border: 0,
          "& .MuiDataGrid-columnHeaderTitle": {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            whiteSpace: "normal", // Enable text wrapping for headers
            wordWrap: "break-word",
            textAlign: "center",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "normal", // Enable text wrapping for cells
            wordWrap: "break-word",
            fontSize: "0.9rem",
            padding: "4px", // Reduce padding for better spacing
            lineHeight: 1.5, // Adjust line height for consistent row heights
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none", // Prevent rows from being cut off
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: "0 !important", // Remove extra space at the bottom
          },
        }}
      />
    </Paper>
  );
}
