/** @format */

import React, { useState } from "react";
import PrimaryButton from "../../Inputs/PrimaryButton";
import InputField from "../../Inputs/InputField";
import { Form, Formik } from "formik";
import { Dialog } from "../../Layout/dialogBox/dialog";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { useCreateOrUpdate } from "../../../Hooks/useCreateOrUpdate";
import { colors } from "../../../Constants/theme";
import SecondaryButton from "../../Inputs/secondaryButton";
import * as yup from "yup";
import { useAlert } from "../../Layout/Alerts/Index";
import { useMediaQuery } from "@mui/material";
import SelectField from "../../Inputs/SelectField/Index";
import { useGetAll } from "../../../Hooks/useGetAll";
import { LiaDonateSolid } from "react-icons/lia";

const validationSchema = yup.object().shape({
  campaign: yup.object().required("Number is required"),
  donation_amount: yup.number().required(" is required"),
  donation_type: yup.object().required("donation type  is required"),
});

function AdminPanelDialog({ onSuccess }) {
  const phone = useMediaQuery("(max-width:751px)");
  const [selectedCampaignId, setSelectedCampaignId] = useState("");

  const { data: campaignData, refetch } = useGetAll({
    key: `/campaign/nt/`,
    select: (data) => data?.data,
    onSuccess: (data) => {},
  });
  const { mutate, isLoading } = useCreateOrUpdate({
    url: "/payment/add-donation/",
    method: "post",
  });
  const addAlert = useAlert();
  const campaignOptions = campaignData?.rows.map((campaign) => ({
    label: campaign.campaign_name,
    value: campaign.campaign_id,
  }));
  const handleCampaignChange = (event) => {
    setSelectedCampaignId(event.target.value);
  };
  console.log(selectedCampaignId, "======>selectedCampaignId");
  const selectedCampaign = campaignData?.rows?.find(
    (campaign) => campaign.campaign_id === selectedCampaignId
  );

  // const tagOptions = [
  //   ...(Array.isArray(selectedCampaign?.tags)
  //     ? selectedCampaign.tags
  //         .filter(
  //           (tag) =>
  //             tag !== "newly added" &&
  //             tag !== "expiring soon" &&
  //             tag !== "need support"
  //         )
  //         .map((tag) => ({
  //           label: tag,
  //           value: tag,
  //         }))
  //     : []),
  //   {
  //     label: "general donation",
  //     value: "general donation",
  //   },
  // ];
  return (
    <div>
      <Dialog
        title={"Add Donation"}
        onClose={() => {}}
        button={
          <PrimaryButton
            sx={{
              height: "45px",
              fontSize: "1rem",
              borderRadius: "8px",
              fontWeight: 500,
              color: colors.text.main,
            }}
          >
            Add Donation
            <LiaDonateSolid size={20} className='ms-1 text-white' />
          </PrimaryButton>
        }
      >
        {({ onClose }) => (
          <Formik
            initialValues={{
              donor_name: "",
              donor_email: "",
              donation_amount: "",
              donation_type: "",
              donation_date: "",
              campaign: "",
              contact_no: "",
              isPaid: true,
              // is_urgent: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              formData.append("donor_name", values.donor_name);
              formData.append("donor_email", values.donor_email);
              formData.append("contact_no", values.contact_no);
              formData.append("donation_amount", values.donation_amount);
              formData.append("donation_type", values.donation_type.value);
              formData.append("donation_date", values.donation_date);
              formData.append("campaign", values.campaign.value);
              formData.append("isPaid", values?.isPaid);

              // formData.append("is_urgent", values.is_urgent);
              mutate(formData, {
                onSuccess: (response) => {
                  addAlert(
                    "Add donataion successfully!",
                    "success",
                    {
                      vertical: "top",
                      horizontal: "right",
                    },
                    3000
                  );
                  onSuccess();

                  onClose();
                },
                onError: (error) => {
                  const errorMessages = error.response?.data
                    ? Object.values(error.response.data).flat().join("\n")
                    : "An unexpected error occurred.";
                  addAlert(
                    errorMessages,
                    "error",
                    {
                      vertical: "top",
                      horizontal: "center",
                    },
                    3000
                  );
                },
              });
            }}
          >
            {({ setFieldValue, values }) => (
              <Form className='flex flex-col items-center max-tablet:px-0'>
                <div className='flex w-full flex-wrap gap-4 justify-center items-center gap-y-2 max-tablet:flex-col max-tablet:gap-4'>
                  <div className='w-[46%] max-tablet:w-[100%]'>
                    <InputField
                      name='donor_name'
                      label='Donor Name'
                      placeholder='Enter donor name'
                    />
                  </div>
                  <div className='w-[46%] max-tablet:w-[100%]'>
                    <InputField
                      name='donor_email'
                      type={"email"}
                      label='Donor Email'
                      placeholder='Enter email'
                    />
                  </div>
                  <div className='w-[46%] max-tablet:w-[100%]'>
                    <InputField
                      name='donation_amount'
                      type={"number"}
                      label='Donation Amount'
                      placeholder='Enter amount'
                    />
                  </div>
                  <div className='w-[46%] max-tablet:w-[100%]'>
                    <SelectField
                      name='campaign'
                      options={campaignOptions}
                      // onChange={(e) => setSelectedCampaignId(e.target?.value)} // Set selected campaign ID
                      label='Campaign'
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root ": {
                          height: phone ? "45px" : "",
                        },
                      }}
                      placeholder='Select Campaign'
                    />
                  </div>
                  <div className='w-[46%] max-tablet:w-[100%]'>
                    <InputField
                      name='donation_date'
                      label='Donation Date'
                      type={"date"}
                      placeholder='Enter date'
                    />
                  </div>
                  <div className='w-[46%] max-tablet:w-[100%]'>
                    <InputField
                      name='contact_no'
                      type={"number"}
                      label='Enter Number'
                      placeholder='Enter mobile number'
                    />
                  </div>

                  <div className='flex w-[46%] max-tablet:w-full'>
                    <SelectField
                      name='donation_type'
                      options={[
                        { label: "Zakat ", value: "Zakat" },
                        { label: "Fitra", value: "Fitra" },
                        {
                          label: "Interest Offloading ",
                          value: "Interest Offloading",
                        },
                        {
                          label: "Hadiya ",
                          value: "Hadiya",
                        },
                        {
                          label: "Sadaqah ",
                          value: "Sadaqah",
                        },
                        {
                          label: "Lillah ",
                          value: "Lillah",
                        },
                        {
                          label: "general donation ",
                          value: "general donation",
                        },
                      ]}
                      label='Donation Type'
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root ": {
                          height: phone ? "45px" : "",
                        },
                      }}
                      placeholder='Select donation type'
                    />
                  </div>
                </div>
                <div className='flex flex-row gap-4 mt-12'>
                  <SecondaryButton
                    onClick={onClose}
                    className='w-[69px] h-[32px] border border-[#082F49]'
                    sx={{
                      height: "44px",
                      width: "100px",
                      border: `1px solid ${colors.primary.dark}`,
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    isLoading={isLoading}
                    sx={{
                      height: "44px",
                      width: "100px",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                    type='submit'
                  >
                    Save
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
}

export default AdminPanelDialog;
