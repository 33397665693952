/** @format */

import React, { useEffect, useState } from "react";
import NavbarCustom from "../../Components/Layout/Navbar/NavbarCustom";
import Footer from "../../Components/Layout/Footer/Index";
import Image from "../../Constants/Image";
import { colors } from "../../Constants/theme";
import SponsorsTale from "../../Components/Layout/SponsorsTable/Index";
import { useGetAll } from "../../Hooks/useGetAll";
import axios from "axios";

const Index = () => {
  const [sponsors, setSponsors] = useState([]);
  const [limit, setLimit] = useState();
  // const [limit, SetLimit] = useState(10);
  // const [rows, setRows] = useState([]);

  // const { data: sponsorslimit } = useGetAll({
  //   key: `/records/sponsors/nt/`,
  //   select: (data) => data,
  //   onSuccess: (data) => {
  //     console.log(data?.data?.total_count, "====>length");
  //     setLimit(data?.data?.total_count);
  //   },
  // });
  const fetchLimit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FE_URL}/records/sponsors/nt/`
      );
      console.log(response?.data.total_count, "========>limitlength");
      setLimit(response?.data.total_count);
      // setSponsors(response?.data?.rows || []); // Ensure it defaults to an empty array if no data is found
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const { data: sponsers } = useGetAll({
    key: `/records/sponsors/nt/?limit=${limit}`,
    select: (data) => data?.data?.rows,
    onSuccess: (data) => {
      setSponsors(data);
    },
  });

  // const fetchDataStats = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_FE_URL}/records/sponsors/nt/?limit=${limit}`
  //     );
  //     setLimit(response?.data.total_count);
  //     setSponsors(response?.data?.rows || []); // Ensure it defaults to an empty array if no data is found
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    fetchLimit();

    // fetchDataStats();
  }, []);
  console.log(limit, "=========>limit");

  return (
    <div>
      <NavbarCustom />
      <div>
        <img
          src={Image.Sponsors}
          alt='Statsimage'
          className='w-full object-cover bg-bottom bg-no-repeat max-tablet:h-[200px] max-desktop:h-[300px] h-[600px]'
        />
      </div>
      {/* ================================================================= */}

      <div className='w-[100%] text-center m-auto bg-white flex flex-col items-center gap-24 max-desktop:gap-[3.75rem] max-tablet:gap-[1.875rem] max-tablet:py-[1.875rem] py-[4.125rem]'>
        <div className='font-light items-center text-xl max-desktop:w-[70%] max-tablet:w-[95%] max-desktop:text-base max-tablet:text-sm justify-center text-center'>
          <span>Narrated by Abu Huraira:</span>
          <br />
          <span>The Messenger of Allah (ﷺ) said:</span>
        </div>
        <div className='flex flex-col items-center max-desktop:w-[70%] max-tablet:w-[95%] justify-center text-center'>
          <p className='font-playfair leading-[28px] max-desktop:text-[2rem] max-tablet:text-2xl font-black text-5xl max-w-[95rem]'>
            Charity does not decrease wealth,
            <br />
            <span className='text-2xl max-desktop:text-xl max-tablet:text-lg leading-[12px] font-normal font-playfair'>
              and Allah increases the honor of a servant who forgives
            </span>
          </p>
        </div>
        <div className='font-lato font-light text-xl max-desktop:text-base max-tablet:text-sm'>
          Sahih al-Bukhari (1390)
        </div>
      </div>

      {/* ================================================================= */}

      <div
        className='w-[100%] text-center m-auto flex flex-col items-center gap-24 max-desktop:gap-[3.75rem] max-tablet:gap-[1.875rem] max-tablet:py-[1.875rem] py-[4.125rem]'
        style={{ background: colors.tertiary.gradient }}
      >
        <div className='font-light font-lateef items-center max-desktop:w-[70%] max-tablet:w-[95%] text-xl max-desktop:text-base max-tablet:text-sm justify-center text-center'>
          <p className='text-xl'>
            : ابوہریرہ رضی اللہ عنہ سے روایت ہے:
            <br /> رسول اللہ صلی اللہ علیہ وسلم نے فرمایا
          </p>
        </div>
        <div className='flex flex-col max-desktop:w-[70%] max-tablet:w-[95%] items-center justify-center text-center'>
          <p className='font-lateef leading-[28px] max-desktop:text-[2rem] max-tablet:text-2xl font-black text-5xl max-w-[95rem]'>
            ،صدقہ دینے سے مال میں کوئی کمی نہیں ہوتی <br />
            <span className='text-2xl leading-[12px] font-normal font-lateef'>
              ، اور اللہ تعالی کسی بندے کو عفو و درگزر کرنے کی بنا پر عزت میں
              اضافہ ہی فرماتا ہے۔
            </span>
          </p>
        </div>
        <div className='font-lateef font-light text-xl max-desktop:text-base max-tablet:text-sm'>
          صحیح بخاری (1390)
        </div>
      </div>

      {/* ================================================================= */}

      <div className='w-[75%] max-desktop:w-[95%] max-tablet:w-[100%] py-[6.25rem] max-tablet:py-[3.75rem] m-auto flex flex-col justify-center items-center'>
        <div className='mb-10'>
          <p className='font-extrabold font-roboto text-center text-4xl max-desktop:text-2xl max-tablet:text-xl leading-[2.625rem] uppercase tracking-tighter'>
            Sponsors of Hafeez-e-Quran Kareem Students
          </p>
          <p className='font-roboto font-normal text-2xl max-desktop:text-xl max-tablet:text-lg leading-7 text-center uppercase tracking-tight'>
            Below is the list of individuals sponsoring the Hafeez-e-Quran
            Kareem students.{" "}
          </p>
        </div>

        <SponsorsTale data={sponsors} length={limit ? limit : 90} />
      </div>

      <Footer />
    </div>
  );
};

export default Index;
