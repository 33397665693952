/** @format */

import React, { useState, useEffect } from "react";
import Table from "../../../Components/Table/Index";
import { useGetAll } from "../../../Hooks/useGetAll";
import { red, green } from "@mui/material/colors";
import AddDonation from "../../../Components/AdminPanelDialogs/AddDonation/Index";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";

function Index() {
  const [rows, setRows] = useState([]);
  const [limit, SetLimit] = useState(10);
  const { data: DonationsLimit, refetch } = useGetAll({
    key: `/payment/add-donation/`,
    select: (data) => data, // Ensure data is an array
    onSuccess: (data) => {
      SetLimit(data?.data?.total_count);
      console.log(data, "<----------dataCount");
    },
  });
  const { data: Donations } = useGetAll({
    key: `/payment/add-donation/?limit=${limit}`,
    select: (data) => data?.data?.rows || [], // Ensure data is an array
    onSuccess: (data) => {
      // console.log(data, "<----------data");
      setRows(mapApiDataToTableRows(data));
    },
  });

  const handleEdit = (id) => {
    console.log("Edit campaign with ID:", id);
    // Add your edit functionality here
  };

  const mapApiDataToTableRows = (apiData) => {
    // Ensure apiData is defined and is an array before filtering
    if (!Array.isArray(apiData)) {
      return [];
    }
    return apiData
      .filter((item) => item.donation_id)
      .map((item, index) => ({
        id: item.donation_id || `unique-${index}`,
        donation_id: item.donation_id,
        campaign: item.campaign?.campaign_name,
        donor_name: item.donor_name,
        donor_email: item.donor_email,
        isPaid: item.isPaid,
        donation_date: new Date(item.donation_date).toLocaleDateString(),
        donation_amount: item.donation_amount,
      }));
  };

  const columns = [
    // { field: "donation_id", headerName: "ID", width: 70 },
    { field: "campaign", headerName: "Campaign Name", width: 70 },

    { field: "donor_name", headerName: "Name", width: 130 },
    { field: "donor_email", headerName: "Email", width: 130 },
    {
      field: "isPaid",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <span
          className='bg-white py-1 px-2 rounded-md'
          style={{
            color: params.value ? green[500] : red[500],
          }}
        >
          {params.value ? "Paid" : "Pending"}
        </span>
      ),
    },
    {
      field: "donation_date",
      headerName: "Date",
      type: "string",
      width: 90,
    },
    {
      field: "donation_amount",
      headerName: "Donate Amount",
      description: "This column has a value getter and is not sortable.",
      width: 160,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,

      renderCell: (params) => (
        <Link to='Edit' state={{ id: params?.row?.donation_id }}>
          {console.log(params?.row?.donation_id, ".........>id")}
          <PrimaryButton
            sx={{
              borderRadius: 1,
              height: "30px",
              background: "#8a8ada",
              "&:hover": {
                background: "#8a8adaba",
              },
            }}
            onClick={() => handleEdit(params?.row?.donation_id)}
          >
            Edit
          </PrimaryButton>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (Donations) {
      setRows(mapApiDataToTableRows(Donations));
    }
  }, [Donations]);

  return (
    <div className=''>
      <Table
        columns={columns}
        rows={rows}
        addButton={<AddDonation onSuccess={refetch} />}
        title='Donations'
        // onDelete={handleDelete}/
        // url={`/campaign/nt/`}
        DeleteUrl={"/payment/del-donation/"}
        refetchUrl={`/payment/add-donation/?limit=${limit}`}
      />
    </div>
  );
}

export default Index;
