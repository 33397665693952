/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { AlertProvider } from "./Components/Layout/Alerts/Index";
import ErrorBoundary from "./utils/ErrorBoundary";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename='/'>
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </AlertProvider>
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

reportWebVitals();
