/** @format */

import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { colors } from "../../Constants/theme";
import NavbarCustom from "../../Components/Layout/Navbar/NavbarCustom";
import Footer from "../../Components/Layout/Footer/Index";
import Image from "../../Constants/Image";

const BankDetailsPage = () => {
  // Replace these with your actual details
  const bankDetails = {
    accountHolder: " Darul uloom rasheedia",
    accountNumber: "1708002100010136",
    ifscCode: "PUNB 0170800",
    bankName: "Punjab national Bank",
  };

  const contactDetails = {
    mobile: "+91 9368012274",
    email: "admin@darululoomrasheedia.org",
  };

  const qrCodeImage = Image.QrCode; // Replace with your QR code image path

  // Media query for responsive design
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  return (
    <>
      <NavbarCustom />
      <Box
        sx={{
          bgcolor: colors.secondary.light, // Background color
          // minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "100px 5px 50px 5px",
        }}
      >
        <Typography
          textAlign={"center"}
          gutterBottom
          fontWeight='bold'
          sx={{
            background: colors.text.gradient,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: { xs: 26, md: 34 },
          }}
        >
          Bank Details & UPI QR Code
        </Typography>

        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: 1200,
            width: "100%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {/* Bank Details Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: isSmallScreen ? "auto" : 350,
              }}
            >
              <Typography
                fontWeight='bold'
                sx={{
                  mb: 2,
                  textAlign: "center",
                  fontSize: { xs: 22, md: 28 },
                }}
              >
                Bank Details
              </Typography>
              <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: 3,
                  height: isSmallScreen ? 200 : 250,
                  overflow: "hidden",
                  width: "100%",
                  paddingBottom: 2,
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: 1.5, md: 1.8 },
                  }}
                >
                  <Typography
                    sx={{ fontSize: { xs: 16, md: 20 } }}
                    // fontWeight='bold'
                    gutterBottom
                  >
                    <strong>Account Holder:</strong> {bankDetails.accountHolder}
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: 16, md: 20 } }}
                    gutterBottom
                  >
                    <strong>Account Number:</strong> {bankDetails.accountNumber}
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: 16, md: 20 } }}
                    gutterBottom
                  >
                    <strong>IFSC Code:</strong> {bankDetails.ifscCode}
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: 16, md: 20 } }}
                    gutterBottom
                  >
                    <strong>Bank Name:</strong> {bankDetails.bankName}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          {/* QR Code Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: isSmallScreen ? "auto" : 350,
              }}
            >
              <Typography
                fontWeight='bold'
                sx={{
                  mb: 2,
                  textAlign: "center",
                  fontSize: { xs: 22, md: 28 },
                }}
              >
                UPI QR Code
              </Typography>
              <Box
                sx={{
                  width: isSmallScreen ? 200 : 250,
                  height: isSmallScreen ? 200 : 250,
                  borderRadius: 4,
                  boxShadow: 2,
                  overflow: "hidden",
                  bgcolor: "#fff",
                  mb: 3,
                }}
              >
                <img
                  src={qrCodeImage}
                  alt='UPI QR Code'
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Contact Information */}
        <Box
          sx={{
            maxWidth: 600,
            width: "100%",
            textAlign: "center",
            mt: 3,
          }}
        >
          <Typography variant='h6' fontWeight='bold' gutterBottom>
            For any transaction-related queries or to share details:
          </Typography>
          <Typography variant='body1'>
            <strong>Mobile:</strong> {contactDetails.mobile}
          </Typography>
          <Typography variant='body1'>
            <strong>Email:</strong> {contactDetails.email}
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default BankDetailsPage;
