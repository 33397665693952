/** @format */

import React, { useState } from "react";
import Table from "../../../Components/Table/Index";
import { useGetAll } from "../../../Hooks/useGetAll";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";
import AdminPanelDialog from "../../../Components/AdminPanelDialogs/AddVideos/Index";
import { Link } from "react-router-dom";

function Index() {
  const [rows, setRows] = useState([]);

  const { data: Videos, refetch } = useGetAll({
    key: `/records/videos/nt/`,
    select: (data) => data.data || [],
    onSuccess: (data) => {
      setRows(mapApiDataToTableRows(data));
    },
  });

  const handleEdit = (slug) => {
    console.log("Edit campaign with ID:", slug);
  };

  const mapApiDataToTableRows = (apiData) => {
    if (!Array.isArray(apiData)) {
      return [];
    }
    return apiData
      .filter((item) => item.id)
      .map((item, index) => ({
        id: item.id || `unique-${index}`,
        slug: item.slug,
        id: item.id,
        title: item.title,
        createdAt: item.createdAt
          ? new Date(item.createdAt).toLocaleDateString()
          : "N/A",
        updatedAt: item.updatedAt
          ? new Date(item.updatedAt).toLocaleDateString()
          : "N/A",
      }));
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "title", headerName: "Title", width: 130 },
    {
      field: "slug",
      headerName: "Slug",
      type: "string",
      width: 90,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      type: "string",
      width: 90,
    },
    {
      field: "updatedAt",
      headerName: "Update Date",
      type: "string",
      width: 90,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Link to='Edit' state={{ id: params?.row?.slug }}>
          {console.log(params, "=======>slug")}
          <PrimaryButton
            sx={{
              borderRadius: 1,
              height: "30px",
              background: "#8a8ada",
              "&:hover": {
                background: "#8a8adaba",
              },
            }}
            onClick={() => handleEdit(params.slug)}
          >
            Edit
          </PrimaryButton>
        </Link>
      ),
    },
  ];
  return (
    <div className=''>
      <Table
        columns={columns}
        DeleteUrl={`/records/delete-multiple-videos/`}
        refetchUrl={"/records/videos/nt/"}
        addButton={<AdminPanelDialog onSuccess={refetch} />}
        rows={rows}
        title='Images'
      />
    </div>
  );
}

export default Index;
