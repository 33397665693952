/** @format */

import React, { useState } from "react";
import Image from "../../../Constants/Image";
import { colors } from "../../../Constants/theme";

let data = [
  {
    img: Image.Df,
    UrduTitle: "دفترِ اہتمام",
    EnglishTitle: "Vice Chancellor Office",
    EnglishContent:
      "Our Daftar-e-Ihtimam is the administrative heart of the madrasa, akin to the Vice Chancellor's Office in a university. This office is responsible for overseeing the overall functioning and strategic direction of the institution. It manages the implementation of policies, academic programs, and administrative procedures. The Vice Chancellor's Office also coordinates with various departments to ensure smooth operations, maintaindiscipline, and uphold the institution's mission and values.",
    UrduContent:
      "ہمارا دفترِ اہتمام مدرسے کا انتظامی دل ہے، جو یونیورسٹی میں وائس چانسلر کے دفتر کی طرح ہے۔ یہ دفتر ادارے کے مجموعی کام اور اسٹریٹجک سمت کی نگرانی کا ذمہ دار ہے۔ یہ پالیسیوں، تعلیمی پروگراموں اور انتظامی طریقہ کار کے نفاذ کا انتظام کرتا ہے۔ وائس چانسلر کا دفتر مختلف شعبوں کے ساتھ مربوط ہوتا ہے تاکہ ہموار آپریشنز کو یقینی بنایا جا سکے، نظم و ضبط کو برقرار رکھا جا سکے، اور ادارے کے مشن اور اقدار کو برقرار رکھا جا سکے۔",
  },
  {
    img: Image.BG2,
    UrduTitle: "تعلیمات",
    EnglishTitle: "Faculty of Education",
    EnglishContent:
      "The Ta'Iimaat or Faculty of Education is dedicated to providing high-quality educational programs. It focuses on developing curricula that combine traditional Islamic studies with modern educational practices. The faculty is staffed by experienced teachers and scholars who are committed to imparting knowledge and fostering a deep understanding of Islamic principles. The Faculty of Education ensures that the educators are well-equipped to deliver effective instruction.",
    UrduContent:
      "تعلیمات یا فیکلٹی آف ایجوکیشن معیاری تعلیمی پروگرام فراہم کرنے کے لئے وقف ہے۔ یہ روایتی اسلامی علوم کو جدید تعلیمی طریقوں کے ساتھ ملا کر نصاب تیار کرنے پر توجہ دیتی ہے۔ فیکلٹی میں تجربہ کار اساتذہ اور علماء شامل ہیں جو علم کی فراہمی اور اسلامی اصولوں کی گہری سمجھ بیدار کرنے کے لئے پرعزم ہیں۔ فیکلٹی آف ایجوکیشن اس بات کو یقینی بناتی ہے کہ اساتذہ موثر تدریس فراہم کرنے کے لئے مکمل طور پر تیار ہوں۔",
  },
  {
    img: Image.BG3,
    UrduTitle: "محاسبی",
    EnglishTitle: "Account Department",
    EnglishContent:
      "The Muhaasibi or Account Department handles all financial matters of the madrasa. This includes budgeting, financial planning, record-keeping, and ensuring compliance with financial regulations. The department is responsible for managing tuition fees, donations, and other sources of income, as well as overseeing expenditures. It plays a crucial role in maintaining the financial health of the institution, ensuring that resources are allocated efficiently to support academic and operational needs.",
    UrduContent:
      "محاسبی یا اکاؤنٹ ڈپارٹمنٹ مدرسہ کے تمام مالی معاملات کی دیکھ بھال کرتا ہے۔ اس میں بجٹ بنانا، مالی منصوبہ بندی، ریکارڈ رکھنا اور مالی قواعد و ضوابط کی پاسداری شامل ہے۔ یہ شعبہ ٹیوشن فیس، عطیات اور دیگر ذرائع آمدنی کا انتظام کرنے کے ساتھ ساتھ اخراجات کی نگرانی بھی کرتا ہے۔ ادارے کی مالی صحت برقرار رکھنے میں اس کا اہم کردار ہوتا ہے، جس سے یہ یقینی بنایا جاتا ہے کہ وسائل تعلیمی اور انتظامی ضروریات کے لئے مؤثر طریقے سے مختص کیے جائیں۔",
  },
  {
    img: Image.BG4,
    UrduTitle: "دار الاقامہ",
    EnglishTitle: "Hostel Office",
    EnglishContent:
      "The Darul Iqamah or Hostel Office manages the accommodation facilities for students residing on campus. It ensures that the hostels are safe, clean, and conducive to a productive living environment. The Hostel Office coordinates room assignments, oversees maintenance and housekeeping services, and addresses any concerns or issues that students may have. It also organizes activities and programs to support the welfare and personal development of the resident students.",
    UrduContent:
      "دار الاقامہ یا ہاسٹل آفس کیمپس میں مقیم طلبہ کے لئے رہائشی سہولتوں کا انتظام سنبھالتا ہے۔ یہ یقینی بناتا ہے کہ ہاسٹلز محفوظ، صاف ستھرے اور ایک مثبت ماحول فراہم کرتے ہیں جو تعلیمی ترقی کے لئے موزوں ہو۔ ہاسٹل آفس کمرے کی تقسیم کو منظم کرتا ہے، دیکھ بھال اور صفائی کے کاموں کی نگرانی کرتا ہے اور طلبہ کے کسی بھی مسئلے یا تشویش کو حل کرتا ہے۔ اس کے علاوہ، یہ طلبہ کی فلاح و بہبود اور ذاتی نشوونما کے لئے سرگرمیاں اور پروگرام بھی منعقد کرتا ہے۔",
  },
  {
    img: Image.BG5,
    UrduTitle: "کتب خانہ",
    EnglishTitle: "Library",
    EnglishContent:
      "The Kutub Khanah or Library is a vital resource center for students and faculty members. It houses a comprehensive collection of Islamic texts, scholarly works, and general educational materials. The library provides access to a wide range of books, journals, and digital resources to support research and learning. It is equipped with quiet study areas, computer terminals, and online databases. The library staff assists users in locating materials and offers guidance on research methodologies.",
    UrduContent:
      "کتب خانہ یا لائبریری طلبہ اور اساتذہ کے لئے ایک اہم وسائل مرکز ہے۔ اس میں اسلامی کتب، علمی کام اور عمومی تعلیمی مواد کا جامع مجموعہ موجود ہے۔ لائبریری تحقیق اور تعلیم کی معاونت کے لئے کتابوں، جرائد اور ڈیجیٹل وسائل تک رسائی فراہم کرتی ہے۔ یہ پرسکون مطالعہ کے علاقوں، کمپیوٹر ٹرمینلز، اور آن لائن ڈیٹا بیس سے لیس ہے۔ لائبریری کا عملہ مواد کی تلاش میں مدد فراہم کرتا ہے اور تحقیق کے طریقہ کار پر رہنمائی پیش کرتا ہے۔",
  },
  {
    img: Image.Df2,
    UrduTitle: " باورچی خانہ",
    EnglishTitle: "Pantry",
    EnglishContent:
      "The Kitchen in our madrasa is a vital hub where nourishment meets care. Our dedicated team ensures that every meal is prepared with utmost hygiene and love, using fresh, high-quality ingredients to provide nutritious and delicious meals. The kitchen operates efficiently, balancing tradition and modernity, to ensure every dish brings comfort and joy. At Darululoom Rashidia, we believe that a well-fed body fosters a well-fed mind, allowing our students to thrive in their studies and personal growth.",
    UrduContent:
      "مدرسہ میں ہمارا باورچی خانہ ایک اہم مرکز ہے جہاں تغذیہ اور دیکھ بھال کا امتزاج ہوتا ہے۔ ہماری محنتی ٹیم اس بات کو یقینی بناتی ہے کہ ہر کھانا صفائی اور محبت کے ساتھ تیار کیا جائے، تازہ اور اعلیٰ معیار کے اجزاء کا استعمال کرتے ہوئے تاکہ غذائیت سے بھرپور اور مزیدار کھانے فراہم کیے جائیں۔ باورچی خانہ نہایت مؤثر طریقے سے کام کرتا ہے، روایت اور جدیدیت کا توازن برقرار رکھتے ہوئے، تاکہ ہر ڈش میں سکون اور خوشی کا احساس ہو۔ جامعۃ الإرشاد میں، ہمارا یقین ہے کہ ایک اچھے کھانے سے جسم کو قوت ملتی ہے، جس سے طلبہ اپنی تعلیم اور ذاتی نشوونما میں بہتر کارکردگی دکھا سکتے ہیں۔",
  },
];

function Index() {
  const [translationStates, setTranslationStates] = useState(
    data.map(() => false)
  );

  const toggleTranslation = (index) => {
    setTranslationStates((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <div className='w-[79.17%] max-desktop:w-[650px] max-tablet:w-[90%] max-tablet:max-w-[500px]  m-auto items-center  py-[100px] max-desktop:py-[80px] max-tablet:py-[60px] flex flex-col gap-[110px] max-desktop:gap-[80px] max-tablet:gap-[60px]'>
      <h1
        className='text-[2.25rem] max-tablet:text-[1.5rem] max-desktop:text-[1.875rem] font-extrabold font-roboto'
        style={{ color: colors.text.light }}
      >
        Departments & Facilities
      </h1>
      {data.map((item, index) => {
        const isEven = index % 2 === 0;
        const isTranslated = translationStates[index];

        return (
          <div key={index} className='w-full'>
            <div
              className={`w-full flex ${
                isEven ? "flex-row" : "flex-row-reverse"
              } max-desktop:flex-col-reverse gap-[32px] justify-center items-center max-desktop:gap-[20px] max-tablet:gap-[10px]`}
            >
              <div className='flex flex-col max-desktop:items-center justify-center gap-[24px] max-tablet:gap-[20px] '>
                <div
                  className='leading-[35px] max-tablet:leading-7 max-desktop:text-center'
                  style={{ color: colors.text.light }}
                >
                  <h1 className='text-[1.75rem] max-desktop:text-[2rem] font-bold font-scheherazade'>
                    {item.UrduTitle}
                  </h1>
                  <h1 className='text-[1.75rem] max-desktop:text-[1.5rem] max-tablet:text-[1.25rem] font-bold font-roboto'>
                    {item.EnglishTitle}
                  </h1>
                </div>
                <p
                  className={`${
                    isTranslated ? "text-[2rem]" : "text-[1.125rem]"
                  } ${
                    isTranslated
                      ? "max-tablet:text-[1.5rem] max-tablet:leading-[27px]  max-desktop:leading-[32px] leading-[33px]"
                      : "max-tablet:text-[1rem] max-tablet:leading-[20px]  max-desktop:leading-[26px] leading-[28px]"
                  }  font-normal  text-justify`}
                  style={{
                    color: colors.text.dark,
                    fontFamily: isTranslated ? "scheherazade" : "roboto",
                  }}
                >
                  {isTranslated ? item.UrduContent : item.EnglishContent}
                </p>
                <p
                  onClick={() => toggleTranslation(index)}
                  className='  font-bold'
                  style={{ color: colors.text.light }}
                >
                  {isTranslated ? "Translate to English" : "Translate to Urdu"}
                </p>
              </div>
              <img
                src={item.img}
                className='rounded-[20px] w-[505px] h-[383px] max-tablet:h-[300px] bg-center object-cover bg-no-repeat'
                style={{ boxShadow: "0px 4px 4px #0000004D" }}
                alt=''
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Index;
