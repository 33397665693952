/** @format */

import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/Index.jsx";
import StatsAndExpense from "./Pages/StatsAndExpenses/Index";
import DepartmentsAndFacilities from "./Pages/Departments&Facilities/Index";
import OngoingCampaign from "./Pages/OngoingCampaign/Index";
import CompletedCampaign from "./Pages/CompletedCampaign/Index";
import BoardOfManagement from "./Pages/BoardOfManagement/Index";
import Login from "./Pages/Login/Index";
import PrivacyPolicy from "./Pages/PrivacyPolicy/Index";
import RefundPolicy from "./Pages/RefundPolicy/Index";
import TermsAndCondition from "./Pages/TermsAndCondition/Index";
import ContactUs from "./Pages/ContactUs/Index";
import AdminRoutes from "./Pages/AdminRoutes/Index";
import AuthState from "./context/authContext/AuthState";
import PublicRoutes from "./utils/PublicRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";
import CampaignByCategory from "./Pages/CampaignByCategory/Index";
import CampaignDetails from "./Pages/CampaignDetails/Index";
import Donate from "./Pages/Donate/Index";
import Sponsors from "./Pages/Sponsors/Index.jsx";
import PageDoesNotExists from "./Pages/PageDoesNotExists/NotFoundPage";
import BankDetails from "./Pages/BankDetails/Index.jsx";
import GallerySubPages from "./Pages/GallerySubPages/Index.jsx";
import Videos from "./Pages/Videos/Index.jsx";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <AuthState>
      <div className='main-container'>
        <ScrollToTop />
        <Routes>
          <Route
            path='/'
            element={
              <PublicRoutes>
                <Home />
              </PublicRoutes>
            }
          />

          <Route
            path='/Overview'
            element={
              <PublicRoutes>
                <AboutUs />
              </PublicRoutes>
            }
          />
          <Route
            path='/Sponsors'
            element={
              <PublicRoutes>
                <Sponsors />
              </PublicRoutes>
            }
          />
          <Route
            path='/Departments&Facilities'
            element={
              <PublicRoutes>
                <DepartmentsAndFacilities />
              </PublicRoutes>
            }
          />
          <Route
            path='/Board-of-Management'
            element={
              <PublicRoutes>
                <BoardOfManagement />
              </PublicRoutes>
            }
          />

          <Route
            path='/Stats-And-Expense'
            element={
              <PublicRoutes>
                <StatsAndExpense />
              </PublicRoutes>
            }
          />
          <Route
            path='/Ongoing-Campaigns'
            element={
              <PublicRoutes>
                <OngoingCampaign />
              </PublicRoutes>
            }
          />
          <Route
            path='/Completed-Campaigns'
            element={
              <PublicRoutes>
                <CompletedCampaign />
              </PublicRoutes>
            }
          />

          <Route
            path='/Login'
            element={
              <PublicRoutes restricted>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path='/Privacy-Policy'
            element={
              <PublicRoutes>
                <PrivacyPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path='/Refund-Policy'
            element={
              <PublicRoutes>
                <RefundPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path='/TermsAndCondition-Policy'
            element={
              <PublicRoutes>
                <TermsAndCondition />
              </PublicRoutes>
            }
          />
          <Route
            path='/Contact-Us'
            element={
              <PublicRoutes>
                <ContactUs />
              </PublicRoutes>
            }
          />
          <Route
            path='/bank-details'
            element={
              <PublicRoutes>
                <BankDetails />
              </PublicRoutes>
            }
          />
          <Route
            path='/Campaign-By-Category/:title'
            element={
              <PublicRoutes>
                <CampaignByCategory />
              </PublicRoutes>
            }
          />
          <Route
            path='/Campaign-Details/:slug'
            element={
              <PublicRoutes>
                <CampaignDetails />
              </PublicRoutes>
            }
          />
          <Route
            path='/Donate/:slug'
            element={
              <PublicRoutes>
                <Donate />
              </PublicRoutes>
            }
          />
          <Route
            path='/Gallery/:slug'
            element={
              <PublicRoutes>
                <GallerySubPages />
              </PublicRoutes>
            }
          />
          <Route
            path='/Videos/:slug'
            element={
              <PublicRoutes>
                <Videos />
              </PublicRoutes>
            }
          />
          <Route
            path='/Admin-Panel/*'
            element={
              <PrivateRoutes>
                <AdminRoutes />
              </PrivateRoutes>
            }
          />

          <Route path='*' element={<PageDoesNotExists />} />
        </Routes>
      </div>
    </AuthState>
  );
}

export default App;
